#pi {
  font-size: 17px;
}
#pi a:not(.nav-link) {
  color: #1888DE;
  font-weight: 700;
}
#pi .nav-link {
  font-size: 18px;
  color: #000;
  margin: 0 15px;
  transition: none;
}
#pi .nav-link.active,
#pi .nav-link:hover {
  background: #1888DE;
  color: #fff;
  border-radius: 8px;
}
#pi p {
  color: #4D4D4D;
  line-height: 1.8em;
  margin-bottom: 0px;
  font-size: 17px;
}
#pi h2,
#pi h3 {
  color: #4D4D4D;
}
#pi h5 {
  font-size: 32px;
  font-weight: bold;
  color: #4D4D4D;
  line-height: 42px;
}
#pi h6 {
  font-size: 24px;
  color: #4D4D4D;
}
#pi .l-h {
  line-height: 1.8em;
}
#pi .text-md {
  font-size: 18px;
}
#pi .text-lg {
  font-size: 32px;
}
#pi .text-xlg {
  font-size: 38px;
}
#pi .text-xxlg {
  font-size: 44px;
}
#pi .text-xxxlg {
  font-size: 48px;
}
#pi .bg-primary {
  background: #2A8AE2 !important;
}
#pi .bg-light {
  background: #e8e8e8 !important;
}
#pi .ant-btn-primary.ant-btn-lg {
  border-radius: 0;
  background: #2A8AE2 !important;
  font-size: 22px !important;
  color: #FFF !important;
}
#pi .check-list {
  padding-left: 0;
}
#pi .check-list li {
  background: url(/images/pi/tick.png);
  background-repeat: no-repeat;
  background-position: 0px 10px;
  padding: 0px 0px 10px 50px;
  background-size: 40px;
  color: #000;
  font-size: 19px;
}
#pi .shadow {
  display: block;
  border-radius: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border: 8px solid rgba(255, 255, 255, 1);
  overflow: hidden;
}

#pi .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow {
  border-bottom: 1px solid rgb(27, 114, 193) !important;
}
#pi .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow.ant-collapse-item-active {
  border-bottom: 24px solid #e8e8e8 !important;
}
#pi .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding: 0;
}

@media only screen and (min-width: 1180px) {
  #pi .container {
    max-width: 1180px;
  }
}
@media only screen and (min-width: 992px) {
  .authors {
    position: absolute;
  }
}

#pi .laptop-top {
  padding-bottom: 90px;
}
#pi .laptop-bottom {
  padding-top: 96px;
}
#pi .laptop {
  position: absolute;
  bottom: -90px;
}
@media only screen and (min-width: 760px) {
  #pi .laptop-top {
    padding-bottom: 150px;
  }
  #pi .laptop-bottom {
    padding-top: 120px;
  }
  #pi .laptop {
    position: absolute;
    bottom: -109px;
    left: 50%;
    transform: translateX(-50%);
  }
}