
.product-card {
	border-radius: 4px;
	transition: all linear 0.2s;
	background: #FFF;
	display: inline-block;
	min-height: 460px;
	height: 100%;
	width: 100%;
	box-shadow: 0px 0px 8px 3px #11111108;
}
.product-card:hover {
	box-shadow: 0px 10px 20px -6px #0000002e, 0px -10px 10px -8px #0000002e;
}
.product-card.no-shadow {
	box-shadow: none;
}
.product-card.no-shadow .product-body {
	padding: 0 !important;
}
.product-card .product-image {
	height: 185px;
	background-size: cover;
	background-position: center;
    background-color: #e8ecf3;
	position: relative;
    transition: all linear 0.2s;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
}
.product-card:hover .product-image {
	margin: 0px;
}
.product-card .product-image button {
	opacity: 0;
    transition: all linear 0.2s;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    z-index: 1
}
.product-card:hover .product-image button,
.product-card .product-image button[disabled] {
	opacity: 1;
}
.product-card:hover .product-image::after {
	content: '';
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background: #001627bd;
	opacity: 0;
}
.product-card:hover .product-image::after {
	opacity: 1;
}
.product-card .product-body {
    color: #333;
}

.ant-tabs-bar {
    background: #FFF;
    text-transform: uppercase;
    font-weight: 700;
}
.ant-tabs-nav .ant-tabs-tab-active {
    color: #00aaff !important;
    font-weight: 700 !important;
}
.ant-tabs-ink-bar {
    background-color: #00aaff !important;
}

.ant-tooltip-inner {
	background: #fff !important;
    text-align: center !important;
    color: #333 !important;
    padding: 0px !important;
}
.ant-tooltip-inner .country-label {
    padding: 8px;
}
.ant-tooltip-inner .enquire-label {
	padding: 8px;
    background: #E3EEF3;
    border-radius: 0px 0px 4px 4px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
	border-top-color: #E3EEF3 !important;
}

.overlay {
  text-align: center;
}

img,
.overlay {
  transition: .3s all;
}

.bg {
  float: left;
  position: relative;
  overflow: hidden;
}
.bg .img {
	width: 100%;
  height: 100%;
	background-size: cover;
}
.bg .overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background: #10171b87;
	color: #fff;
	opacity: 1;
	text-shadow: -0.5px -0.5px 0px #11111180;
}
.bg .overlay h2,
.bg .overlay h3,
.bg .overlay h4 {
	padding-top: 24%;
	color: #fff !important;
}
.bg:hover .overlay {
	opacity: 1;
	background: #5c6f7996;
}

#homepage section h4 {
	color: #0079C4;
}

#who-are-we h4 {
	text-align: center;
}
.font-weight-semibold {
  font-weight: 600!important;
}

.text-secondary li a {
  color: inherit;
}

.progress-circle {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.progress-circle__value {
  stroke-dasharray: 530;
  stroke-dashoffset: 530;
  -webkit-animation: progress 2s;
          animation: progress 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.progress-circle-projects {
  -webkit-animation: progress 2s 1.5s;
          animation: progress 2s 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.progress-circle-organizations {
  -webkit-animation: progress 2s 3s;
          animation: progress 2s 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes progress {
  0% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes progress {
  0% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#pi {
  font-size: 17px;
}
#pi a:not(.nav-link) {
  color: #1888DE;
  font-weight: 700;
}
#pi .nav-link {
  font-size: 18px;
  color: #000;
  margin: 0 15px;
  transition: none;
}
#pi .nav-link.active,
#pi .nav-link:hover {
  background: #1888DE;
  color: #fff;
  border-radius: 8px;
}
#pi p {
  color: #4D4D4D;
  line-height: 1.8em;
  margin-bottom: 0px;
  font-size: 17px;
}
#pi h2,
#pi h3 {
  color: #4D4D4D;
}
#pi h5 {
  font-size: 32px;
  font-weight: bold;
  color: #4D4D4D;
  line-height: 42px;
}
#pi h6 {
  font-size: 24px;
  color: #4D4D4D;
}
#pi .l-h {
  line-height: 1.8em;
}
#pi .text-md {
  font-size: 18px;
}
#pi .text-lg {
  font-size: 32px;
}
#pi .text-xlg {
  font-size: 38px;
}
#pi .text-xxlg {
  font-size: 44px;
}
#pi .text-xxxlg {
  font-size: 48px;
}
#pi .bg-primary {
  background: #2A8AE2 !important;
}
#pi .bg-light {
  background: #e8e8e8 !important;
}
#pi .ant-btn-primary.ant-btn-lg {
  border-radius: 0;
  background: #2A8AE2 !important;
  font-size: 22px !important;
  color: #FFF !important;
}
#pi .check-list {
  padding-left: 0;
}
#pi .check-list li {
  background: url(/images/pi/tick.png);
  background-repeat: no-repeat;
  background-position: 0px 10px;
  padding: 0px 0px 10px 50px;
  background-size: 40px;
  color: #000;
  font-size: 19px;
}
#pi .shadow {
  display: block;
  border-radius: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border: 8px solid rgba(255, 255, 255, 1);
  overflow: hidden;
}

#pi .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow {
  border-bottom: 1px solid rgb(27, 114, 193) !important;
}
#pi .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow.ant-collapse-item-active {
  border-bottom: 24px solid #e8e8e8 !important;
}
#pi .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding: 0;
}

@media only screen and (min-width: 1180px) {
  #pi .container {
    max-width: 1180px;
  }
}
@media only screen and (min-width: 992px) {
  .authors {
    position: absolute;
  }
}

#pi .laptop-top {
  padding-bottom: 90px;
}
#pi .laptop-bottom {
  padding-top: 96px;
}
#pi .laptop {
  position: absolute;
  bottom: -90px;
}
@media only screen and (min-width: 760px) {
  #pi .laptop-top {
    padding-bottom: 150px;
  }
  #pi .laptop-bottom {
    padding-top: 120px;
  }
  #pi .laptop {
    position: absolute;
    bottom: -109px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.bg-primary h4 {
  color: #fff !important;
}
.bg-light h4 {
  color: #3c5767;
}

/* .product-bg {
  background-size: cover;
  color: #FFF;
  position: relative;
  background-color: #39a4ca;
  background-position: center;
}
.product-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: linear-gradient(#07253a47, #0a5880d1);
} */

.product-page h5 {
  color: #2080c7 !important;
  margin-bottom: 16px;
}
.product-page ul {
  text-align: left;
  font-size: 14px;
  padding-left: 0px;
}
.product-page ul:not(.slick-dots) li {
  margin-bottom: 8px;
  background: url(/images/tick-box.png) no-repeat left;
  padding: 3px 0px 3px 40px;
  list-style: none;
}
.product-page .ant-card li {
  margin-bottom: 4px;
  background: url(/images/filled-circle1.png) no-repeat left;
  padding: 3px 0px 3px 30px;
  list-style: none;
}

.product-section-title span {
  background: #FFF;
  color: #8098b1;
  padding: 0px 20px;
  position: relative;
  z-index: 1;
}
.product-section-title:after {
  content: "";
  border-bottom: 1px solid #abc3dc;
  display: block;
  bottom: 12px;
  position: relative;
  z-index: 0;
}
.product-sidebar {
  margin-top: -200px;
}
.notify-link {
  color: #2080c7 !important;
  text-decoration: underline !important;
}

.program-ouline-image {
  width: 35%;
  position: absolute;
  background-size: cover;
  top: 0;
  bottom: 0;
  background-position: center;
}
.bg-light .program-ouline-image {
  left: 0;
}
.bg-primary .program-ouline-image {
  right: 0;
  background-color: #85a1ba;
  background-blend-mode: luminosity;
}

.ant-collapse {
  border: none !important;
  background: transparent !important;
}
.ant-collapse-header {
  background: #f6f8fc;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 3px solid #fff !important;
}
.ant-collapse-content {
  border-top: 1px solid rgb(216, 223, 236) !important;
  background: transparent !important;
}

.btn-facebook {
  background: #3B5998;
  color: #FFF !important;
}
.btn-linkedin {
  background: #4875B4;
  color: #FFF !important;
}
.btn-twitter {
  background: #1da1f2;
  color: #FFF !important;
}
.btn-email {
  background: #ffa44f;
  color: #fff !important;
}
.ant-collapse img {
  max-width: 100%;
}
body {
  margin: 0;
  padding: 0;
}

