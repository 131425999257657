
.product-card {
	border-radius: 4px;
	transition: all linear 0.2s;
	background: #FFF;
	display: inline-block;
	min-height: 460px;
	height: 100%;
	width: 100%;
	box-shadow: 0px 0px 8px 3px #11111108;
}
.product-card:hover {
	box-shadow: 0px 10px 20px -6px #0000002e, 0px -10px 10px -8px #0000002e;
}
.product-card.no-shadow {
	box-shadow: none;
}
.product-card.no-shadow .product-body {
	padding: 0 !important;
}
.product-card .product-image {
	height: 185px;
	background-size: cover;
	background-position: center;
    background-color: #e8ecf3;
	position: relative;
    transition: all linear 0.2s;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
}
.product-card:hover .product-image {
	margin: 0px;
}
.product-card .product-image button {
	opacity: 0;
    transition: all linear 0.2s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1
}
.product-card:hover .product-image button,
.product-card .product-image button[disabled] {
	opacity: 1;
}
.product-card:hover .product-image::after {
	content: '';
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background: #001627bd;
	opacity: 0;
}
.product-card:hover .product-image::after {
	opacity: 1;
}
.product-card .product-body {
    color: #333;
}

.ant-tabs-bar {
    background: #FFF;
    text-transform: uppercase;
    font-weight: 700;
}
.ant-tabs-nav .ant-tabs-tab-active {
    color: #00aaff !important;
    font-weight: 700 !important;
}
.ant-tabs-ink-bar {
    background-color: #00aaff !important;
}

.ant-tooltip-inner {
	background: #fff !important;
    text-align: center !important;
    color: #333 !important;
    padding: 0px !important;
}
.ant-tooltip-inner .country-label {
    padding: 8px;
}
.ant-tooltip-inner .enquire-label {
	padding: 8px;
    background: #E3EEF3;
    border-radius: 0px 0px 4px 4px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
	border-top-color: #E3EEF3 !important;
}

.overlay {
  text-align: center;
}

img,
.overlay {
  transition: .3s all;
}

.bg {
  float: left;
  position: relative;
  overflow: hidden;
}
.bg .img {
	width: 100%;
  height: 100%;
	background-size: cover;
}
.bg .overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background: #10171b87;
	color: #fff;
	opacity: 1;
	text-shadow: -0.5px -0.5px 0px #11111180;
}
.bg .overlay h2,
.bg .overlay h3,
.bg .overlay h4 {
	padding-top: 24%;
	color: #fff !important;
}
.bg:hover .overlay {
	opacity: 1;
	background: #5c6f7996;
}

#homepage section h4 {
	color: #0079C4;
}

#who-are-we h4 {
	text-align: center;
}