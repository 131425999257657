.bg-primary h4 {
  color: #fff !important;
}
.bg-light h4 {
  color: #3c5767;
}

/* .product-bg {
  background-size: cover;
  color: #FFF;
  position: relative;
  background-color: #39a4ca;
  background-position: center;
}
.product-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: linear-gradient(#07253a47, #0a5880d1);
} */

.product-page h5 {
  color: #2080c7 !important;
  margin-bottom: 16px;
}
.product-page ul {
  text-align: left;
  font-size: 14px;
  padding-left: 0px;
}
.product-page ul:not(.slick-dots) li {
  margin-bottom: 8px;
  background: url(/images/tick-box.png) no-repeat left;
  padding: 3px 0px 3px 40px;
  list-style: none;
}
.product-page .ant-card li {
  margin-bottom: 4px;
  background: url(/images/filled-circle1.png) no-repeat left;
  padding: 3px 0px 3px 30px;
  list-style: none;
}

.product-section-title span {
  background: #FFF;
  color: #8098b1;
  padding: 0px 20px;
  position: relative;
  z-index: 1;
}
.product-section-title:after {
  content: "";
  border-bottom: 1px solid #abc3dc;
  display: block;
  bottom: 12px;
  position: relative;
  z-index: 0;
}
.product-sidebar {
  margin-top: -200px;
}
.notify-link {
  color: #2080c7 !important;
  text-decoration: underline !important;
}

.program-ouline-image {
  width: 35%;
  position: absolute;
  background-size: cover;
  top: 0;
  bottom: 0;
  background-position: center;
}
.bg-light .program-ouline-image {
  left: 0;
}
.bg-primary .program-ouline-image {
  right: 0;
  background-color: #85a1ba;
  background-blend-mode: luminosity;
}

.ant-collapse {
  border: none !important;
  background: transparent !important;
}
.ant-collapse-header {
  background: #f6f8fc;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 3px solid #fff !important;
}
.ant-collapse-content {
  border-top: 1px solid rgb(216, 223, 236) !important;
  background: transparent !important;
}

.btn-facebook {
  background: #3B5998;
  color: #FFF !important;
}
.btn-linkedin {
  background: #4875B4;
  color: #FFF !important;
}
.btn-twitter {
  background: #1da1f2;
  color: #FFF !important;
}
.btn-email {
  background: #ffa44f;
  color: #fff !important;
}