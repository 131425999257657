.font-weight-semibold {
  font-weight: 600!important;
}

.text-secondary li a {
  color: inherit;
}

.progress-circle {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.progress-circle__value {
  stroke-dasharray: 530;
  stroke-dashoffset: 530;
  -webkit-animation: progress 2s;
          animation: progress 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.progress-circle-projects {
  -webkit-animation: progress 2s 1.5s;
          animation: progress 2s 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.progress-circle-organizations {
  -webkit-animation: progress 2s 3s;
          animation: progress 2s 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes progress {
  0% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes progress {
  0% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 0;
  }
}